import React from 'react';
import styled from 'styled-components';
import LanguageSelector from './LanguageSelector';

const Bubble = styled.div`

    position: fixed;
    bottom: 5px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 20000;
`;

const FloatingBubble = () => {
    return (
        <Bubble>
            <LanguageSelector />
        </Bubble>
    );
};

export default FloatingBubble;