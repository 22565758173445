import React, { useEffect, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import FloatingBubble from './components/FloatingBubble'; 
import CookieBanner from './components/CookieBanner';
import PhoneDisplay from './components/PhoneDisplay';

import './App.css';

// Utility function to add preload to lazy imports
const preloadLazyComponent = (importFunc) => {
    const Component = lazy(importFunc);
    Component.preload = importFunc;
    return Component;
};

const Home = preloadLazyComponent(() => import('./pages/Home'));
const About = preloadLazyComponent(() => import('./pages/About'));
const Menu = preloadLazyComponent(() => import('./pages/Menu'));
// const Contact = preloadLazyComponent(() => import('./pages/Contact'));
const NousTrouver = preloadLazyComponent(() => import('./pages/NousTrouver'));
const Legal = preloadLazyComponent(() => import('./pages/Legal'));

// Utility function to preload media resources
const preloadResource = (url, type) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = url;
    link.as = type;
    document.head.appendChild(link);
};

const preloadMedia = () => {
    preloadResource('/video1.webm', 'video');
    preloadResource('/video2.webm', 'video');
};

function App() {
    useEffect(() => {
        preloadMedia();
        Home.preload();
        About.preload();
        Menu.preload();
        // Contact.preload();
        NousTrouver.preload();
        Legal.preload();
    }, []);

    return (
        <Router>
            <Navbar />
            <ScrollToTop />
            <React.Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/menu" element={<Menu />} />
                    <Route path="/about" element={<About />} />
                    {/* <Route path="/contact" element={<Contact />} /> */}
                    <Route path="/NousTrouver" element={<NousTrouver />} />
                    <Route path="/Legal" element={<Legal />} />
                </Routes>
            </React.Suspense>
            <Footer />
            <CookieBanner />
            <FloatingBubble />
            <PhoneDisplay phoneNumber="04 95 24 10 89" />
        </Router>
    );
}

export default App;