import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #3A6EA5;
  color: #ffffff;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Logo = styled(Link)`
  flex: 0.95;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  overflow: hidden;

  img {
    height: 150px;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 50%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 72%);
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Hours = styled.div`
  flex: 1;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Address = styled.div`
  flex: 1;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

// const FooterLink = styled(Link)`
//   color: #61dafb;
//   text-decoration: none;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

const CopyrightContainer = styled.div`
  background-color: #2A4A6C;
  color: #ffffff;
  text-align: center;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  display: flex;
  flex-direction: column; /* Permet de mettre le lien en dessous du copyright */
  align-items: center;
`;

const LegalLink = styled(Link)`
  color: #61dafb;
  margin-top: 2px; /* Espace au-dessus du lien */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
	const { t } = useTranslation();

  return (
    <>
      <FooterContainer>
        <Logo to="/">
          <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="Restaurant Logo" />
        </Logo>
        
        <Hours>
          <h3>{t('Horaires')}</h3>
          <p>{t('Mardi - Dimanche: 18:30–23:00')}</p>
          <p>{t('Lundi: Fermé')}</p>
        </Hours>

        <Address>
          <h3>{t('Adresse')}</h3>
          <p>{t('4 Rue François Pietri')}</p>
          <p>{t('20090 Ajaccio')}</p>
          <p>{t('France')}</p>
          {/* <FooterLink to="/contact">{t('Contactez-nous')}</FooterLink> */}
        </Address>
      </FooterContainer>
      
      <CopyrightContainer>
        &copy; {new Date().getFullYear()} Acqua & Farina. {t('Tous droits réservés')}
        <LegalLink to="/legal">{t('Mentions légales')}</LegalLink> {/* Lien stylé et placé en dessous */}
      </CopyrightContainer>
    </>
  );
};

export default Footer;