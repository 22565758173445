import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Conteneur du menu burger
const BurgerMenu = styled.div`
  position: fixed;
  top: 30px;
  left: 20px;
  z-index: ${({ isOpen }) => (isOpen ? '1000' : '900')}; /* Z-index conditionnel */
  cursor: pointer;
  transition: transform 0.3s ease; /* Transition pour le déplacement horizontal */

  ${({ isOpen }) => isOpen && `
    transform: translateX(10px);
  `}

  @media (max-width: 768px) {
    &:hover {
      ${({ isOpen }) => isOpen && `
        transform: translateX(10px);
      `}
    }
  }
`;


const Logo = styled(Link)`
  flex: 0.95;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 150px;
  img {
    height: 150px;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 50%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 60%);
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;



// Conteneur des lignes du menu burger
const BurgerLines = styled.div`
  position: relative;
  width: 35px; /* Largeur du bouton burger */
  height: 25px; /* Hauteur totale */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;

  div {
    width: 35px;
    height: 3px; /* Hauteur des lignes */
    background: white;
    border-radius: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  ${({ isOpen }) => isOpen && `
    div:nth-child(1) {
      transform: rotate(45deg) translateY(15px);
    }
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(3) {
      transform: rotate(-45deg) translateY(-15px);
    }
  `}
`;

// Panneau latéral
const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 300px; /* Largeur du panneau latéral sur PC */
  height: 150%;
  background: rgba(58, 110, 165, 0.8);
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease;
  z-index: 998;
  
  /* Largeur réduite pour les écrans mobiles */
  @media (max-width: 768px) {
    width: 250px;
  }
`;

// Conteneur du titre dans le panneau latéral
const SidebarLogo = styled.div`
  padding: 23px;
  padding-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A4A6C;

  h1 {
    color: white;
    margin: 0;
    font-size: 1.25em;
    font-weight: bold;
  }
`;

// Liens du menu
const SidebarLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  transition: background 0.3s ease;

  &:hover {
    background: #2A4A6C;
  }
`;

// Vérifie si l'utilisateur est connecté
// const isLoggedIn = !!localStorage.getItem('token');

// Conteneur des liens
const SidebarMenu = styled.div`
  margin-top: 20px;
`;

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);
    const burgerRef = useRef(null);

    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Si le clic est en dehors du menu et du bouton burger, fermer le menu
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) &&
                burgerRef.current && !burgerRef.current.contains(event.target) && isOpen) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const { t } = useTranslation();

    return (
        <>
            <BurgerMenu ref={burgerRef} onClick={toggleSidebar} isOpen={isOpen}>
                <BurgerLines isOpen={isOpen}>
                    <div />
                    <div />
                    <div />
                </BurgerLines>
            </BurgerMenu>
            <Sidebar ref={sidebarRef} isOpen={isOpen}>
                <SidebarLogo>
                    <h1>Acqua & Farina</h1> {/* Remplace l'image par le titre */}
                </SidebarLogo>
                <SidebarMenu>
                    <SidebarLink to="/" onClick={() => setIsOpen(false)}>{t('ACCUEIL')}</SidebarLink>
                    <SidebarLink to="/menu" onClick={() => setIsOpen(false)}>{t('LA CARTE')}</SidebarLink>
                    <SidebarLink to="/about" onClick={() => setIsOpen(false)}>{t('QUI SOMMES-NOUS ?')}</SidebarLink>
                    <SidebarLink to="/NousTrouver" onClick={() => setIsOpen(false)}>{t('NOUS TROUVER')}</SidebarLink>
                    {/* <SidebarLink to="/contact" onClick={() => setIsOpen(false)}>{t('CONTACT')}</SidebarLink> */}
                    {/* {isLoggedIn && (
                        <SidebarLink to="/admin" onClick={() => setIsOpen(false)}>{t('ADMIN')}</SidebarLink>
                    )} */}
                    <Logo to="/">
                      <img src={`${process.env.PUBLIC_URL}/logo.jpg`} alt="Restaurant Logo" />
                    </Logo>
                </SidebarMenu>
            </Sidebar>
        </>
    );
};

export default Navbar;