import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const resources = {
  en: {
    translation: {

      // Cookies
      "Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez l'utilisation des cookies.": "We use cookies to improve your experience on our site. By continuing your navigation, you accept the use of cookies.",
      "En savoir plus": "Learn more",
      "Accepter": "Accept",
      "Refuser": "Decline",
      "Mentions légales": "Legal notice",

      // Home
      "Bienvenue dans notre Restaurant !": "Welcome to our Restaurant !",
      "Bienvenue au restaurant Acqua & Farina ! Nous sommes ravis de vous accueillir pour une expérience culinaire exceptionnelle. Découvrez nos plats savoureux et laissez-vous tenter par nos spécialités maison." : "Welcome to Acqua & Farina restaurant! We are delighted to welcome you for an exceptional culinary experience. Discover our tasty dishes and let yourself be tempted by our house specialties.",
      
      // Menu
      "Plat": "Dish",
      "Boisson": "drink",
      "Cave": "Wine Cellar",
      "Apéro": "Aperitif",
      "Favoris": "Favorites",
      "Desserts": "Dessert",


    



      // Navbar
      "ACCUEIL": "HOME",
      "LA CARTE": "MENU",
      "QUI SOMMES-NOUS ?": "ABOUT US",
      "NOUS TROUVER": "FIND US",
      "CONTACT": "CONTACT",

      // Dropdown
      "Accueil": "Home",
      "La Carte": "Menu",
      "Qui sommes-nous ?": "About Us",
      "Nous trouver": "Find Us",
      "Contact": "Contact",

      // footer
      "Horaires": "Hours",
      "Adresse": "Address",
      "Lundi - Vendredi: 9h - 18h": "Monday - Friday: 9am - 6pm",
      "Samedi: 10h - 17h": "Saturday: 10am - 5pm",
      "Dimanche: Fermé": "Sunday: Closed",
      "123 Rue Exemple": "123 Example Street",
      "France": "France",
      "Contactez-nous": "Contact Us",
      "Tous droits réservés": "All rights reserved"
    }
  },
  fr: {
    translation: {
   

    }
  },
  it: {
    translation: {
      "Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez l'utilisation des cookies.": "Utilizziamo i cookie per migliorare la tua esperienza sul nostro sito. Continuando la navigazione, accetti l'uso dei cookie.",
      "En savoir plus": "Per saperne di più",
      "Accepter": "Accettare",
      "Refuser": "Rifiutare",
      "Mentions légales": "Note legali",

      "Bienvenue dans notre Restaurant !": "Benvenuti nel nostro ristorante !",
      "Bienvenue au restaurant Acqua & Farina ! Nous sommes ravis de vous accueillir pour une expérience culinaire exceptionnelle. Découvrez nos plats savoureux et laissez-vous tenter par nos spécialités maison." : "Benvenuti al ristorante Acqua & Farina! Siamo lieti di darvi il benvenuto per un'esperienza culinaria eccezionale. Scoprite i nostri piatti gustosi e lasciatevi tentare dalle nostre specialità casalinghe.",

      "ACCUEIL": "HOME",
      "LA CARTE": "MENU",
      "QUI SOMMES-NOUS ?": "CHI SIAMO ?",
      "NOUS TROUVER": "TROVARCI",
      "CONTACT": "CONTATTO",

      "Accueil": "Home",
      "La Carte": "Menu",
      "Qui sommes-nous ?": "Chi siamo ?",
      "Nous trouver": "Trovarci",
      "Contact": "Contatto",

      "Horaires": "Orari",
      "Adresse": "Indirizzo",
      "Lundi - Vendredi: 9h - 18h": "Lunedì - Venerdì: 9h - 18h",
      "Samedi: 10h - 17h": "Sabato: 10h - 17h",
      "Dimanche: Fermé": "Domenica: Chiuso",
      "123 Rue Exemple": "123 Rue Exemple",
      "France": "France",
      "Contactez-nous": "Contattaci",
      "Tous droits réservés": "Tutti i diritti riservati",
      
      "Menu": "Menu",
      "Plat": "Piatto",
      "Boisson": "bevanda",
      "Cave": "Cantina",
      "Apéro": "Aperitivo",
      "Favoris": "Preferiti",
      "Desserts": "Dolci",

    }
  },
  es: {
    translation: {

      "Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez l'utilisation des cookies.": "¡Utilizamos cookies para mejorar su experiencia en nuestro sitio! Al continuar navegando, acepta el uso de cookies.",
      "En savoir plus": "Saber más",
      "Accepter": "Aceptar",
      "Refuser": "Rechazar",
      "Mentions légales": "Avisos legales",

      "Bienvenue dans notre Restaurant !": "¡Bienvenido a nuestro restaurante!",
      "Bienvenue au restaurant Acqua & Farina ! Nous sommes ravis de vous accueillir pour une expérience culinaire exceptionnelle. Découvrez nos plats savoureux et laissez-vous tenter par nos spécialités maison." : "¡Bienvenido al restaurante Acqua & Farina! Estamos encantados de darle la bienvenida para una experiencia culinaria excepcional. Descubra nuestros deliciosos platos y déjese tentar por nuestras especialidades caseras.",

      "ACCUEIL": "INICIO",
      "LA CARTE": "MENÚ",
      "QUI SOMMES-NOUS ?": "¿QUIÉNES SOMOS?",
      "NOUS TROUVER": "ENCONTRARNOS",
      "CONTACT": "CONTACTO",

      "Accueil": "Inicio",
      "La Carte": "Menú",
      "Qui sommes-nous ?": "¿Quiénes somos?",
      "Nous trouver": "Encontrarnos",
      "Contact": "Contacto",

      "Horaires": "Horario",
      "Adresse": "Dirección",
      "Lundi - Vendredi: 9h - 18h": "Lunes - Viernes: 9h - 18h",
      "Samedi: 10h - 17h": "Sábado: 10h - 17h",
      "Dimanche: Fermé": "Domingo: Cerrado",
      "123 Rue Exemple": "123 Rue Exemple",
      "France": "France",
      "Contactez-nous": "Contáctenos",
      "Tous droits réservés": "Todos los derechos reservados",

      "Menu": "Menú",
      "Plat": "Plato",
      "Boisson": "bebida",
      "Cave": "Bodega",
      "Apéro": "Aperitivo",
      "Favoris": "Favoritos",
      "Desserts": "Postres",
      

    }
  }


};

i18n
  .use(initReactI18next) // Connecte i18next avec React
  .init({
    resources,
    lng: "fr", // Langue par défaut
    fallbackLng: "fr", // Langue de secours si la traduction n'existe pas
    interpolation: {
      escapeValue: false // React protège déjà contre les XSS
    }
  });

export default i18n;
