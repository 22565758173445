// PhoneDisplay.js
import React from 'react';
import './PhoneDisplay.css'; // Pour les styles

const PhoneDisplay = ({ phoneNumber }) => {
  return (
    <div className="phone-display">
      <a href={`tel:${phoneNumber}`} className="phone-number">
        {phoneNumber}
      </a>
    </div>
  );
};

export default PhoneDisplay;