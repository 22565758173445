import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Bubble = styled.div`
  top: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #3A6EA5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    }
    
    &:active {
      transform: scale(0.95);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      background-color: #adb5bd;
      }
      `;
      
      const FlagWrapper = styled.div`
      width: 30px;
      height: 20px;
      border-radius: 5px;
      overflow: hidden;
      `;
      
      const FlagImage = styled.img`
      width: 100%;
      height: 100%;
      display: block;
      `;
      
      const LanguageDropdown = styled.div`
      z-index: 550000000;
      top: 80px;
      
    position: fixed;
    right: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    max-width: 200px;
    width: auto;
    max-height: 300px;
    overflow-y: auto;
  `;

const LanguageButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const bubbleRef = useRef(null);
  const dropdownRef = useRef(null);

  // Utilisation de useMemo pour éviter la recréation de l'array languages à chaque rendu
  const languages = useMemo(() => [
    { code: 'fr', name: 'Français', flag: 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg' },
    { code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg' },
    { code: 'it', name: 'Italiano', flag: 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg' },
    { code: 'es', name: 'Español', flag: 'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg' }
  ], []); 

  const currentLanguage = languages.find(lang => lang.code === i18n.language);

  const toggleDropdown = () => setDropdownOpen(prev => !prev);

  const changeLanguage = (lng) => {
    const consentGiven = localStorage.getItem('cookiesConsent');
  
    if (consentGiven === 'accepted') {
      localStorage.setItem('preferredLanguage', lng);
    }
  
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };
  
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current && 
      !dropdownRef.current.contains(event.target) && 
      !bubbleRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookiesConsent');
    
    if (consentGiven === 'accepted') {
      const savedLanguage = localStorage.getItem('preferredLanguage');
      
      if (savedLanguage && languages.some(lang => lang.code === savedLanguage)) {
        i18n.changeLanguage(savedLanguage);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [i18n, languages]);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Bubble ref={bubbleRef} onClick={toggleDropdown}>
        <FlagWrapper>
          <FlagImage
            src={currentLanguage.flag}
            alt={currentLanguage.name}
          />
        </FlagWrapper>
      </Bubble>

      {dropdownOpen && (
        <LanguageDropdown ref={dropdownRef}>
          {languages.map(({ code, name, flag }) => (
            code !== currentLanguage.code && (
              <LanguageButton
                key={code}
                onClick={() => changeLanguage(code)}
              >
                <FlagWrapper>
                  <FlagImage src={flag} alt={name} />
                </FlagWrapper>
                <span style={{ marginLeft: '10px' }}>{name}</span>
              </LanguageButton>
            )
          ))}
        </LanguageDropdown>
      )}
    </div>
  );
};

export default LanguageSelector;