import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


// Container for the banner
const BannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #343a40;
  color: white;
  padding: 30px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

// Container for the content (text and buttons)
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;  // Adjust this value for better control over the banner width
  padding: 0 20px;
  box-sizing: border-box;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

// Container for the text
const TextWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  max-width: 100%;

  a {
    color: #007bff;
    margin-left: 5px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 576px) {
    margin-bottom: 0;
    font-size: 16px;
    text-align: left;
    max-width: 70%; // Increase the width for better text display on larger screens
  }
`;

// Container for buttons
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px; // Space between buttons
  justify-content: center;
  width: 100%;  // Make sure the button container takes the full width available
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
    width: auto;  // Reset width on larger screens
  }
`;

// Button styling
const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: #004494;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 576px) {
    font-size: 16px;
    padding: 12px 20px;
  }
`;

// Floating bubble for reopening the banner
const ReopenBubble = styled.div`
  position: fixed;
  bottom: 20px;
  left: 15px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  font-weight: bold;
  z-index: 999;
`;

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if consent has already been given
    const consentGiven = localStorage.getItem('cookiesConsent');
    if (!consentGiven) {
      setShowBanner(true);
    } else if (consentGiven === 'rejected') {
      // Show the bubble if consent was rejected
      setShowBanner(false);
    }
  }, []);

  const handleAccept = () => {
    // Save consent in localStorage
    localStorage.setItem('cookiesConsent', 'accepted');
    setShowBanner(false);
  };

  const handleReject = () => {
    // Clear localStorage and save refusal
    localStorage.removeItem('cookiesConsent');
    localStorage.setItem('cookiesConsent', 'rejected');
    setShowBanner(false);
  };

  const handleReopen = () => {
    setShowBanner(true);
  };

	const { t } = useTranslation();


  return (
    <>
      {showBanner && (
        <BannerWrapper>
          <ContentWrapper>
            <TextWrapper>
              {t("Nous utilisons des cookies pour améliorer votre expérience sur notre site. En poursuivant votre navigation, vous acceptez l'utilisation des cookies.")}
              <Link to="/legal">{t('En savoir plus')}</Link> 
            </TextWrapper>
            <ButtonWrapper>
              <Button onClick={handleAccept}> {t('Accepter')} </Button>
              <Button onClick={handleReject}> {t('Refuser')} </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </BannerWrapper>
      )}
      {localStorage.getItem('cookiesConsent') === 'rejected' && (
        <ReopenBubble onClick={handleReopen}>?</ReopenBubble>
      )}
    </>
  );
};

export default CookieBanner;